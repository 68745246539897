var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-popup',{style:({ height: '85%' }),attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"pop_main"},[_c('div',{staticClass:"tips-btn",on:{"click":function($event){_vm.show = false}}},[_vm._v(" 收起，进入编辑器 ")]),_c('div',{staticClass:"list-box"},[(_vm.params.classifyId1 === 1)?_c('div',{staticClass:"flower_type"},_vm._l((_vm.flower.types),function(item){return _c('div',{key:item.id,class:{ flower_type_actived: _vm.params.classifyId3 === item.id },on:{"click":function($event){_vm.params.classifyId3 = item.id;
                    _vm.reset();}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0):_c('div',{staticClass:"flower_type"},_vm._l((_vm.container.types),function(item){return _c('div',{key:item.id,class:{ flower_type_actived: _vm.params.classifyId2 === item.id },on:{"click":function($event){_vm.params.classifyId2 = item.id;
                    _vm.reset();}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"list"},[(_vm.params.classifyId1 === 1)?_c('div',[(_vm.$store.state.routeParams.type === 'practice')?[_c('div',{staticClass:"lv_flex flower_other"},[_c('div',{staticClass:"lv_flex_center",on:{"click":function($event){_vm.flower.otherActived = 1}}},[(_vm.activedColor.name === '颜色')?_c('i',{staticClass:"iconfont icon-yanse"}):_c('div',{staticClass:"active_color",style:({ background: _vm.activedColor.color })}),_vm._v(" "+_vm._s(_vm.activedColor.name)+" ")]),_c('div',{staticClass:"lv_flex_center",on:{"click":function($event){_vm.flower.otherActived = 2}}},[_c('i',{staticClass:"iconfont icon-kaifang"}),_vm._v(_vm._s(_vm.params.floweringStage ? _vm.params.floweringStage + "月" : "花季")+" ")]),_c('div',{staticClass:"lv_flex_center",class:{ actived_like: _vm.params.queryLikeMaterial },on:{"click":function($event){_vm.params.queryLikeMaterial = !_vm.params.queryLikeMaterial;
                            _vm.reset();}}},[_c('i',{staticClass:"iconfont icon-xingxing"}),_vm._v("常用 ")])])]:_vm._e(),(_vm.flower.otherActived === 1)?_c('div',{staticClass:"color_list"},_vm._l((_vm.flower.colors),function(item){return _c('div',{key:item.id,style:({ background: item.color }),on:{"click":function($event){_vm.params.colorClassifyId = item.id;
                        _vm.reset();}}})}),0):_vm._e(),(_vm.flower.otherActived === 2)?_c('div',{staticClass:"month_list"},[_c('div',{on:{"click":function($event){_vm.params.floweringStage = null;
                        _vm.reset();}}},[_vm._v(" 取消 ")]),_vm._l((12),function(item){return _c('div',{key:item,on:{"click":function($event){_vm.params.floweringStage = item + '';
                        _vm.reset();}}},[_vm._v(" "+_vm._s(item)+"月 ")])})],2):_vm._e(),_c('div',{staticClass:"mb_20"},[_c('van-search',{attrs:{"placeholder":"请输入搜索关键词","show-action":"","background":"#f7f7f7"},on:{"search":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.reset()}}},[_vm._v("搜索")])]},proxy:true}],null,false,1099837537),model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1)],2):_vm._e(),(_vm.params.classifyId1 === 2)?_c('div',[(_vm.$store.state.routeParams.type === 'practice')?[_c('div',{staticClass:"lv_flex flower_other"},[_c('div',{staticClass:"lv_flex_center",class:{ actived_like: _vm.params.queryLikeMaterial },on:{"click":function($event){_vm.params.queryLikeMaterial = !_vm.params.queryLikeMaterial;
                            _vm.reset();}}},[_c('i',{staticClass:"iconfont icon-xingxing"}),_vm._v("常用 ")])])]:_vm._e(),_c('div',{staticClass:"mb_20"},[_c('van-search',{attrs:{"placeholder":"请输入搜索关键词","show-action":"","background":"#F4F6EE"},on:{"search":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.reset()}}},[_vm._v("搜索")])]},proxy:true}],null,false,1099837537),model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1)],2):_vm._e(),_c('van-list',{staticClass:"van_list",attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.loadData},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"flower",style:({
                            backgroundImage: `url(https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/${item.coverImage})`,
                        }),on:{"click":function($event){return _vm.addItem(item.id || item.materialId)}}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(_vm.$store.state.routeParams.type === 'practice')?_c('i',{staticClass:"iconfont icon-xingxing",class:{ 'icon-xingxing1': item.likeMaterial },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.changeLike(item)}}}):_vm._e()])}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }