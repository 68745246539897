import axios from 'axios';
let baseURL = 'https://api.lvshuhualv.com/';

let authToken;

let isDev = process.env.NODE_ENV === 'development';
if (isDev) {
    // baseURL = 'https://ikuai0.haloom.cc:7991';
    authToken = '9db30e34-1217-435b-ade3-17959f45c1f4';
    localStorage.setItem('authToken', authToken);
}

let ajax = axios.create({
    baseURL,
    withCredentials: true,
});

ajax.interceptors.request.use(function (config) {
    !config.headers.authToken &&
        Object.assign(config.headers, {
            authToken: isDev ? authToken : localStorage.getItem('authToken'),
        });

    return config;
});
ajax.interceptors.response.use(
    function (res) {
        return res.data;
    },
    function (error) {
        return Promise.reject('网络错误');
    }
);

export default ajax;
