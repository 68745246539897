import { REAL_TO_EDITOR_RATE, INIT_Y, BINDING_DEFAULT_Y } from '../../variable/constant'
import createStem from '../../line/createStem'
import getPointsArr from '../../line/getPointsArr'
import { reomveObjectFromScene } from '../../utils/index'

class FlowerObject {
    constructor(initData) {
        let { resource, position, env, params, id } = initData;
        //id
        this.id = id || 'flower_' + new Date().getTime();
        //资源
        this.resource = resource;
        //环境
        this.env = env;
        //模型mesh
        this.modelMesh = {
            flowerMesh: null,
            stemMesh: null,
            touchHelperMesh: null
        };
        //真实mesh
        this.realMesh = {
            flowerMesh: null,
            stemMesh: null,
        };
        //配置项
        this.params = params || {
            //困扎点
            bindingY: 0,
            //面向
            faceIndex: 1,
            //大小
            size: 1
        }
        this.init(position)
    }

    //初始化
    init (position) {
        const detail = this.resource.detail;
        //花头模型
        const flowerMesh = new THREE.Mesh(new THREE.CylinderGeometry((detail.flower.radiusTop - 0) * REAL_TO_EDITOR_RATE, (detail.flower.radiusBottom - 0) * REAL_TO_EDITOR_RATE, (detail.flower.height - 0) * REAL_TO_EDITOR_RATE, 32), new THREE.MeshPhysicalMaterial({ color: detail.flower.color }));
        let startPosition = this.env.objects.jsContainer.position;

        if (position) {
            flowerMesh.position.set(position.x, position.y, position.z);
        } else {
            //偏移
            let shifting = sessionStorage.getItem('shifting') || 0;
            //添加位置时进行偏移以保证最后添加的在前面显示
            let viewVec3 = new THREE.Vector3().copy(this.env.three.viewController.object.position);
            viewVec3 = viewVec3.divideScalar(viewVec3.length());
            let initVec3 = new THREE.Vector3(startPosition.x, INIT_Y, startPosition.z).addScaledVector(viewVec3, shifting - 0);
            flowerMesh.position.set(initVec3.x, initVec3.y, initVec3.z);
            sessionStorage.setItem('shifting', shifting - 0 + 5);

        }

        this.modelMesh.flowerMesh = flowerMesh;
        //花头指向到该对象
        flowerMesh.parentObject = this;
        this.env.three.scene.add(flowerMesh);
        //根模型
        let stemMesh = createStem({
            startPosition,
            position: flowerMesh.position,
            bindingType: this.env.params.render.bindingType,
            bindingY: BINDING_DEFAULT_Y + this.params.bindingY,
            dom: this.env.params.dom,
            materialOption: {
                color: detail.stem.color,
                linewidth: detail.stem.radiusStem
            }
        })
        this.modelMesh.stemMesh = stemMesh;
        this.env.three.scene.add(stemMesh);
        //触摸点模型
        const touchHelperMesh = new THREE.Mesh(new THREE.SphereGeometry(10, 32, 16), new THREE.MeshBasicMaterial({ color: detail.flower.color }));
        this.modelMesh.touchHelperMesh = touchHelperMesh;
        touchHelperMesh.visible = false;
        this.env.three.scene.add(touchHelperMesh);

        if (position) {
            this.updateModel();
        }
    }

    //更新模型
    updateModel (sourceStartPosition) {
        let startPosition = this.env.objects.jsContainer.position;

        //花头缩放
        this.modelMesh.flowerMesh.scale.x = this.params.size;
        this.modelMesh.flowerMesh.scale.y = this.params.size;
        this.modelMesh.flowerMesh.scale.z = this.params.size;
        //捆扎点
        const bindingY = BINDING_DEFAULT_Y + this.params.bindingY;

        //更新花头位置
        if (sourceStartPosition) {
            let sourcePosition = this.modelMesh.flowerMesh.position;
            sourcePosition.x = (sourcePosition.x - sourceStartPosition.x) + startPosition.x;
            sourcePosition.y = (sourcePosition.y - sourceStartPosition.y) + startPosition.y;
            sourcePosition.z = (sourcePosition.z - sourceStartPosition.z) + startPosition.z;
        }

        //转动模型花头
        this.modelMesh.flowerMesh.lookAt(startPosition.x, bindingY, startPosition.z);
        this.modelMesh.flowerMesh.rotateX(-Math.PI / 2.0);




        //设置根路径
        this.modelMesh.stemMesh.geometry.setPositions(
            getPointsArr({
                startPosition,
                position: this.modelMesh.flowerMesh.position,
                bindingType: this.env.params.render.bindingType,
                bindingY: bindingY
            })
        )
    }

    //清除真实mesh
    clearReal (modelVisiable) {
        if (this.realMesh.flowerMesh) {
            //清除花头
            reomveObjectFromScene(this.env.three.scene, this.realMesh.flowerMesh);
            this.realMesh.flowerMesh = null;
            //清除根部
            reomveObjectFromScene(this.env.three.scene, this.realMesh.stemMesh);
            this.realMesh.stemMesh = null;
            //处理触摸点
            this.modelMesh.touchHelperMesh.visible = false;
            this.modelMesh.flowerMesh.visible = modelVisiable;
            this.modelMesh.stemMesh.visible = modelVisiable;
        }
    }

    //移除
    remove () {
        this.env.three.objController.detach();
        if (this.realMesh.flowerMesh) {
            reomveObjectFromScene(this.env.three.scene, this.realMesh.flowerMesh);
            reomveObjectFromScene(this.env.three.scene, this.realMesh.stemMesh);
        }
        reomveObjectFromScene(this.env.three.scene, this.modelMesh.touchHelperMesh);
        reomveObjectFromScene(this.env.three.scene, this.modelMesh.flowerMesh);
        reomveObjectFromScene(this.env.three.scene, this.modelMesh.stemMesh);
        for (let i = 0; i < this.env.objects.flowers.length; i++) {
            if (this.id === this.env.objects.flowers[i].id) {
                this.env.objects.flowers.splice(i, 1);
                break;
            }
        }
    }

    //更换资源
    changeResource (resource) {
        this.resource = resource;
        this.modelMesh.flowerMesh.material.color.set(this.resource.detail.flower.color);
        this.modelMesh.stemMesh.material.color.set(this.resource.detail.stem.color);
    }

    //旋转
    rotate (val) {
        let faceIndex = this.params.faceIndex + val;
        const angles = this.resource.detail.angles[0];
        const maxLength = angles.length;
        if (faceIndex > maxLength) {
            faceIndex = 1;
        }
        if (faceIndex < 1) {
            faceIndex = maxLength;
        }
        this.params.faceIndex = faceIndex;
        return faceIndex;
    }
}

export default FlowerObject;