import Vue from 'vue';
import axios from 'axios';
import { Toast } from 'vant';
let baseURL = 'https://api.lvshuhualv.com/';
let authToken;
let isDev = process.env.NODE_ENV === 'development';
if (isDev) {
    // baseURL = 'https://ikuai0.haloom.cc:7991/';
    authToken = '9db30e34-1217-435b-ade3-17959f45c1f4';
    // localStorage.setItem('authToken', authToken);
}

let ajax = axios.create({
    baseURL,
    withCredentials: true,
});
let ajaxNum = 0;
ajax.interceptors.request.use(config => {
    !config.headers.authToken &&
        Object.assign(config.headers, {
            authToken: isDev ? authToken : localStorage.getItem('authToken'),
        });
    console.log(config);

    if (ajaxNum === 0) {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0,
        });
    }
    ajaxNum++;
    return config;
});

// 添加响应拦截器
ajax.interceptors.response.use(
    res => {
        ajaxNum--;
        if (ajaxNum === 0) {
            Toast.clear();
        }
        // 对响应数据做点什么
        const code = res.data.code || 200;
        if (code === 401) {
        } else if (code !== 200) {
            if (code === 403) {
                return Promise.reject(res.data);
            }
            Toast(res.data.msg);
            return Promise.reject(res.data);
        }
        return res.data;
    },
    error => { }
);

Vue.prototype.$ajax = ajax;
Vue.prototype.$axios = axios;
